import React from "react";
import LayoutDefault from "../components/LayoutDefault";

export default function contact() {
  return (
    <LayoutDefault>
      <h1>Contact us</h1>
      <p>Contact information, warranty information</p>
    </LayoutDefault>
  );
}
